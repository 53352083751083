<template>
  <v-data-table
    v-model="selectedItems"
    :items-per-page="data.items.length || 0"
    class="grey--text text--darken-2 pa-5"
    :page.sync="currentPage"
    :items="data.items"
    :headers="data.headers"
    :loading="data.isLoading"
    item-key="id"
    loading-text="Carregando dados, por favor aguarde"
    no-results-text="Nenhum resultado encontrado"
    no-data-text="Nenhum dado encontrado"
    hide-default-footer
    checkbox-color="primary"
    show-select
    fixed-header
    height="60vh"
  >
    <template v-slot:top>
      <v-row class="mb-2">
        <v-col
          cols="4"
          class="d-flex align-center"
        >
          <h1 class="text-h5 primary--text">
            {{ data.title }}
          </h1>

          <slot name="title-action" />
        </v-col>

        <v-col
          class="d-flex"
          cols="8"
        >
          <v-text-field
            v-model="searched"
            class="mr-2"
            placeholder="Buscar"
            append-icon="fas fa-search"
            hide-details
            filled
            rounded
            dense
          />

          <v-badge
            v-if="data.headerButtons.filter"
            bordered
            color="info"
            :content="$store.state.filterDrawer.filterCount"
            :value="$store.state.filterDrawer.filterCount"
            overlap
            offset-x="20"
          >
            <v-btn
              class="mx-2"
              color="primary"
              rounded
              @click="actionFilter"
            >
              Filtro
              <v-icon right>
                fas fa-filter
              </v-icon>
            </v-btn>
          </v-badge>
          <slot
            name="group"
            :selectedItems="selectedItems"
          />
          <slot name="new-btn">
            <v-btn
              v-if="data.headerButtons.new && showNewBtn"
              class="mx-2"
              color="primary"
              rounded
              @click="actionNew"
            >
              Novo
              <v-icon right>
                fas fa-plus
              </v-icon>
            </v-btn>
          </slot>
        </v-col>
      </v-row>

      <MassActionToolbar
        :selected="selectedItems"
        :current-component="data.massAction"
      />
    </template>

    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item.id"
          @dblclick="actionEdit($event, item)"
          @click.right="actionContextMenu($event, item)"
        >
          <td>
            <v-checkbox
              v-model="selectedItems"
              :value="item"
              style="margin: 0px; padding: 0px"
              hide-details
            />
          </td>
          <slot
            name="tbody"
            :item="item"
          />
        </tr>
      </tbody>
    </template>

    <template v-slot:footer>
      <TableFooter
        :pages-counter="totalPages"
        :current-page="currentPage"
        :items-per-page="data.items.length || 0"
        @setCurrentPage="setCurrentPage"
      />
    </template>
  </v-data-table>
</template>

<script>
import { contextMenu } from '@/main.js'
import { mapMutations } from 'vuex'
import TableFooter from './TableFooter'
import debounce from 'lodash/debounce'

export default {
  components: {
    TableFooter,
    MassActionToolbar: () => import('@/components/MassActionToolbar'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    showNewBtn: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    searched: null,
    openedArtDialog: false,
    selectedItems: [],
    currentPage: 1,
    date_created_at: null,
    showBigImages: false,
  }),
  watch: {
    searched: debounce(function (value) {
      this.currentPage = 1
      this.$emit('search', value)
    }, 500),
  },
  methods: {
    ...mapMutations(['setFilterDrawer']),
    actionNew() {
      this.$router.push({ name: this.data.new, params: { isComposed: false } })
    },
    actionNewComposed() {
      this.$router.push({ name: this.data.new, params: { isComposed: true } })
    },
    actionNewTemporary() {
      this.$emit('newTemporaryProduct')
    },
    actionEdit(event, item) {
      this.$router.push({
        name: this.data.edit,
        params: { id: item.id },
      })
    },
    actionFilter() {
      this.setFilterDrawer({
        isOpened: true,
        component: this.data.filter,
      })
    },
    actionContextMenu(event, item) {
      contextMenu.open(event, item, this.data.context)
    },
    checkActive(status) {
      switch (status) {
        case true:
          return ''
        case false:
          return 'error'
      }
    },
    setCurrentPage(value) {
      this.currentPage = value
      this.$emit('setCurrentPage', value)
    },
    checkActiveIcon(status) {
      switch (status) {
        case true:
          return ''
        case false:
          return 'fas fa-bell'
      }
    },
  },
}
</script>

<style lang="scss">
.u-object-fit {
  object-fit: cover;
}
tr,
td,
tbody,
.v-avatar {
  transition: all 0.3s !important;
}
</style>
