<template>
  <v-row class="mt-5">
    <v-col
      cols="12"
      class="d-flex justify-space-between align-center"
    >
      <div v-if="total">
        <v-pagination
          v-model="page"
          :length="total"
          :total-visible="5"
          next-icon="fas fa-angle-right"
          prev-icon="fas fa-angle-left"
          circle
        />
        <div>
          <p class="text-caption text-center">
            Página atual
          </p>
        </div>   
      </div>
      <div>
        <p class="text-center">
          Total de itens por página: {{ itemsPerPage }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
    export default {
        props: {
            pagesCounter: {
              type: Number,
              default:  1
            },
            itemsPerPage: {
              type: Number,
              default:  1
            },
            currentPage: {
              type: Number,
              default:  1
            }
        },
        data() {
            return {
                total: 0,
            }
        },
        computed: {
            page: {
                get: function() {
                    return this.currentPage
                },
                set: function(value) {
                    this.$emit('setCurrentPage', parseInt(value))
                }
            },
            perPage: {
                get: function() {
                    return this.itemsPerPage
                },
                set: function(value) {
                    this.$emit('setItemsPerPage', parseInt(value))
                }
            },
        },
        watch: {
          pagesCounter: function() {
              this.setTotalPages()
          }
        },
        methods: {
            setTotalPages() {
              this.total = this.pagesCounter
              return this.total
            }
        }
    }
</script>